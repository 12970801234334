import {
  ArrowTrendingDownIcon,
  CloudArrowDownIcon,
  ServerStackIcon,
  RectangleGroupIcon,
  MagnifyingGlassIcon,
  ChartPieIcon,
} from "@heroicons/react/20/solid";

type Feature = {
  name: string;
  description: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
};

const features: Feature[] = [
  {
    name: "Function Optimization",
    description:
      "The memory you assign a function controls the RAM the function can use, how much CPU it can use, and how much the function will cost. We can find that perfect number for you.",
    icon: CloudArrowDownIcon,
  },
  {
    name: "Resource Discovery",
    description:
      "Discover all the lambda functions you have deployed on your AWS account in all regions you have access to. You can delete,update, or have us track any of these functions",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Cost Breakdowns and Projections",
    description:
      "See a full breakdown of you cloud costs and how much we will save you by optimizing your lambda functions.",
    icon: ArrowTrendingDownIcon,
  },
  {
    name: "Optimization Flows",
    description:
      "Some functions will need other things to happen in between each run during optimizaion. Our optimization flows will allow you to set up a set of functions to be optimized together.",
    icon: RectangleGroupIcon,
  },
  {
    name: "Resource Management",
    description:
      "You can manually or delete any lambda function in your account through our platform. Out platform is the best way to manage your lambda functions accross regions.",
    icon: ServerStackIcon,
  },
  {
    name: "Function Stats",
    description:
      "We will pull all the stats for your lambda functions without you having to change any code or add any extensions or layers. Through Cloudwatch, we will pull your tracked functions' statistics on a regular interval.",
    icon: ChartPieIcon,
  },
];

export function Features() {
  return (
    <div id="feature" className="pt-16 mt-16 sm:mt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-makefast-blue">
            Start Saving Now
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            You aren't getting the most out of Serverless
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Before you embark on that R*st rewrite, we know one of your
            engineers is pitching, let us optimize your current lambda functions
            first.
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            alt="App screenshot"
            src="https://tailwindui.com/plus/img/component-images/dark-project-app-screenshot.png"
            width={2432}
            height={1442}
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
          />
          <div aria-hidden="true" className="relative">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <feature.icon
                  aria-hidden="true"
                  className="absolute left-1 top-1 h-5 w-5 text-makefast-blue"
                />
                {feature.name}
              </dt>
              {": "}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
