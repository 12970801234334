import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import {
  BoltIcon,
  CalendarDaysIcon,
  UsersIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import { NavBar } from "./nav-bar";
import { HomePageTopHero } from "./top-hero";
import { Features } from "./features";
import { Cta } from "./cta";
import { Pricing } from "./pricing";
import { Integrations } from "./integrations";
import { Footer } from "./footer";

export function HomePage() {
  return (
    <div className="bg-gray-900">
      <main>
        {/* Hero section */}
        <HomePageTopHero />
        {/* Features */}
        <Features />
        {/* CTA section */}
        <Cta />
        {/* Pricing section */}
        <Pricing />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}
