import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

type Tier = {
  name: string;
  id: string;
  href: string;
  price: Record<string, string>;
  description: string;
  restriction?: string;
  features: string[];
  mostPopular: boolean;
};

const frequencies = [
  { value: "annually", label: "Annually", priceSuffix: "/year" },
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
];
const tiers: Tier[] = [
  {
    name: "Indie Hacker",
    id: "tier-indie-hacker",
    href: "#",
    price: { monthly: "$19", annually: "$199" },
    description: "The essentials to provide your best work for clients.",
    restriction: "< $1000/mth Cloud Spend",
    features: [
      "Resource Optimization",
      "Resources Discovery",
      "Resource Management",
      "Cloud Cost Breakdown and Projections",
      "12-Hour Resource Statistics",
      "48-hour support response time",
    ],
    mostPopular: false,
  },
  {
    name: "Business",
    id: "tier-business",
    href: "#",
    price: { monthly: "$49", annually: "$499" },
    description: "A plan that scales with your rapidly growing business.",
    features: [
      "All of Indie Hacker",
      "Automated Resource Optimization",
      "Organizations",
      "Optimization Flows",
      "1-Hour Resource Statistics",
      "12-hour support response time",
    ],
    mostPopular: true,
  },
  // {
  //   name: "Enterprise",
  //   id: "tier-enterprise",
  //   href: "#",
  //   price: { monthly: "$199", annually: "$1999" },
  //   description: "Dedicated support and infrastructure for your company.",
  //   features: [
  //     "All of Startup",
  //     "Automatic Invitations",
  //     "Multi-factor Authentication",
  //     "SAML Authentication",
  //     "Custom Roles and Permissions",
  //     "1-hour, dedicated support response time",
  //   ],
  //   mostPopular: false,
  // },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div id="pricing" className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-makefast-blue">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Pricing plans for teams of&nbsp;all&nbsp;sizes
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
          Choose an affordable plan that’s packed with features for ensuring you
          are getting the maximum performance, at the lowest cost, for your
          serverless infrastructure.
        </p>
        <div className="mt-16 flex justify-center">
          <fieldset aria-label="Payment frequency">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
            >
              {frequencies.map((option) => (
                <Radio
                  key={option.value}
                  value={option}
                  className="cursor-pointer rounded-full px-2.5 py-1 data-[checked]:bg-makefast-blue"
                >
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          </fieldset>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "bg-white/5 ring-2 ring-makefast-blue"
                  : "ring-1 ring-white/10",
                "rounded-3xl p-8 xl:p-10",
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className="text-lg font-semibold leading-8 text-white"
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-makefast-blue px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-300">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-white">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-300">
                  {frequency.priceSuffix}
                </span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-makefast-blue text-white shadow-sm hover:bg-makefast-blue focus-visible:outline-makefast-blue"
                    : "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white",
                  "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                )}
              >
                Buy plan
              </a>
              <Guarantee frequency={frequency.value} />
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-white"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

type GuaranteeInput = {
  frequency: string;
};

function Guarantee(input: GuaranteeInput) {
  if (input.frequency != "annually") {
    return null;
  }

  return (
    <div className="pt-2 flex flex-row items-center justify-center">
      <b className="mt-2 text-md leading-6 text-white">
        90 Day Moneyback guarantee
      </b>
    </div>
  );
}

type RestrictionsInput = {
  restrictions?: string;
};
